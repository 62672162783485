import Router from 'vue-router';
import ErrorPage from './views/ErrorPage';
import PersonalDetails from './views/PersonalDetails';
import Login from './views/Login';
import OfferNegativeDecision from './views/OfferNegativeDecision';
import Start from './views/Start';
import ThankYou from './views/ThankYou';
import { pages, taskDefinitionKeys, ROLE } from './constants';
import BankDetails from './views/BankDetails';
import DocumentsPage from './views/DocumentsPage';
import AddressPage from './views/AddressPage';
import Offer from './views/Offer';
import ContractPageLoader from './views/ContractPageLoader';
import SignContract from './views/SignContract';
import SpouseAddressPage from './views/spouse/SpouseAddressPage';
import SpousePersonalDetails from './views/spouse/SpousePersonalDetails';
import IncomesLiabilities from './views/IncomesLiabilities';
import SpouseOrCoBorrowerIncomesLiabilities from './views/SpouseOrCoBorrowerIncomesLiabilities';
import SubmitSpouseDetails from './views/SubmitSpouseDetails';
import OldContinue from './views/OldContinue';
import OfferReviewConditionalNegative from './views/OfferReviewConditionalNegative';
import OfferReviewHousingLoan from './views/OfferReviewHousingLoan';
import Diginet from './views/iFrames/Diginet';
import Autoplius from './views/iFrames/Autoplius';
import WhatsNextPageBankLink from './views/WhatsNextPageBankLink';
import SelectProduct from './views/SelectProduct';
import SpouseFinanceData from './views/spouse/SpouseFinanceData';
import ChooseContinueOption from './views/ChooseContinueOption';
import SubmitRealEstateInfo from './views/SubmitRealEstateInfo';
import SubmitBankAccountNumber from './views/SubmitBankAccountNumber';
import { CO_APPLICANT_ROLES } from '@/constants/application';
import ProvideBasViaOpenBanking from './views/ProvideBasViaOpenBanking';
import SubmitCoborrowerAddressAndFinancialData from './views/SubmitCoborrowerAddressAndFinancialData';
import SubmitBankDetailsAndAddress from '@/views/SubmitBankDetailsAndAddress';
import { getFallbackWebLoanApplicationUrl, getMigratedSteps, isFeatureRedirectionToFallbackUrlEnabled } from './config';

export const partnerRoutes = [
  {
    path: '/diginet/:productName',
    name: pages.diginet,
    component: Diginet,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/autoplius/:productName',
    name: pages.autoplius,
    component: Autoplius,
    meta: { disableSessionExpiration: true },
  },
];

export const offerRoutes = [
  {
    path: '/offer-approved',
    name: taskDefinitionKeys.ReviewApproved,
    component: Offer,
  },
  {
    path: '/offer-approved-upsale',
    name: taskDefinitionKeys.ReviewApprovedUpsale,
    component: Offer,
  },
  {
    path: '/offer-approved-final',
    name: taskDefinitionKeys.ReviewApprovedFinal,
    component: Offer,
  },
  {
    path: '/offer-approved-upsale-final',
    name: taskDefinitionKeys.ReviewApprovedUpsaleFinal,
    component: Offer,
  },
  {
    path: '/read-conditional-positive-offer',
    name: taskDefinitionKeys.ReadConditionalPositiveOffer,
    component: Offer,
  },
  {
    path: '/offer-approved-leasing',
    name: taskDefinitionKeys.ReviewApprovedLeasing,
    component: Offer,
  },
  {
    path: '/offer-review-conditional-negative',
    name: taskDefinitionKeys.ReviewConditionalNegative,
    component: OfferReviewConditionalNegative,
  },
  {
    path: '/offer-review-rejected-bring-co-borrower',
    name: taskDefinitionKeys.ReviewRejectedBringCoBorrower,
    component: OfferReviewConditionalNegative,
  },
  {
    path: '/offer-review-housing-loan',
    name: taskDefinitionKeys.InformCustomerAboutHousingLoanOffer,
    component: OfferReviewHousingLoan,
  },
];

export const thankYouRoutes = [
  {
    path: '/result1',
    name: taskDefinitionKeys.DuplicateApplicationThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result2',
    name: taskDefinitionKeys.UnexpectedDecisionOnContractGenerationThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result4',
    name: taskDefinitionKeys.SpouseIdentifiedAndDataSubmittedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result5',
    name: taskDefinitionKeys.AdditionalDataSubmittedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result6',
    name: taskDefinitionKeys.DocumentUploadSkippedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result7',
    name: taskDefinitionKeys.DocumentsUploadedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result8',
    name: taskDefinitionKeys.UploadedRefinancingDocumentsThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result9',
    name: taskDefinitionKeys.EmploymentDocumentsUploadedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result10',
    name: taskDefinitionKeys.SpouseFinanceDataSubmittedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result11',
    name: taskDefinitionKeys.SpouseDocumentsUploadedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result12',
    name: taskDefinitionKeys.LeasingDocumentsUploadedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result13',
    name: taskDefinitionKeys.CallCustomerThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result14',
    name: taskDefinitionKeys.ReadHousingLoanRejectedPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result15',
    name: taskDefinitionKeys.WaitForHousingLoanDecisionThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result16',
    name: taskDefinitionKeys.HousingLoanDocumentsUploadedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result17',
    name: taskDefinitionKeys.WaitForHousingLoanDecisionThankYouPageConditionalPositive,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result18',
    name: taskDefinitionKeys.ApplicationSubmittedThankYou,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result19',
    name: taskDefinitionKeys.ReadUndecidedDecision,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/result20',
    name: taskDefinitionKeys.ConsentSignedThankYouPage,
    component: ThankYou,
    meta: { disableSessionExpiration: true },
  },
];

const spouseRoutes = [
  {
    path: '/spouse/personal-data',
    name: taskDefinitionKeys.SubmitSpousePersonalData,
    component: SpousePersonalDetails,
  },
  {
    path: '/spouse/identify',
    name: pages.spouseIdentification,
    component: SpousePersonalDetails,
  },
  {
    path: '/spouse/address',
    name: taskDefinitionKeys.SubmitSpouseAddress,
    component: SpouseAddressPage,
  },
  {
    path: '/spouse/finance-data',
    name: taskDefinitionKeys.SubmitSpouseFinanceData,
    component: SpouseFinanceData,
  },
  {
    path: '/spouse/documents',
    name: taskDefinitionKeys.UploadSpouseDocuments,
    props: {
      canSelectPreviouslyUploadFile: true,
      shouldSetFilesForAllSections: true,
    },
    component: DocumentsPage,
  },
  {
    path: '/spouse/personal-details',
    name: taskDefinitionKeys.SubmitSpouseDetails,
    component: SubmitSpouseDetails,
  },
];

const errorPageRoutes = [
  {
    path: '/maintenance',
    name: pages.maintenance,
    component: ErrorPage,
    props: { errorType: pages.maintenance },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/error',
    name: pages.error500,
    component: ErrorPage,
    props: { errorType: pages.error500 },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/session-expired',
    name: pages.sessionExpired,
    component: ErrorPage,
    props: { errorType: pages.sessionExpired },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/no-task',
    name: pages.noTask,
    component: ErrorPage,
    props: { errorType: pages.noTask },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/unauthorized',
    name: pages.unauthorized,
    component: ErrorPage,
    props: { errorType: pages.unauthorized },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/no-process',
    name: pages.noProcess,
    component: ErrorPage,
    props: { errorType: pages.noProcess },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/unavailable',
    name: pages.error451,
    component: ErrorPage,
    props: { errorType: pages.error451 },
    meta: { disableSessionExpiration: true },
  },
  {
    path: '*',
    name: pages.error404,
    component: ErrorPage,
    props: { errorType: pages.error404 },
    meta: { disableSessionExpiration: true },
  },
];

const reusedRoutes = [{
  path: '/employment-documents',
  name: taskDefinitionKeys.UploadEmploymentDocuments,
  props: { canSelectPreviouslyUploadFile: true },
  component: DocumentsPage,
}];

const startRoutes = [
  {
    path: '/complete-remote-identification',
    name: pages.completeRemoteIdentification,
    component: Start,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/complete-online-identification',
    name: pages.completeOnlineSigning,
    component: Start,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/complete-login-service-identification',
    name: pages.completeLoginServiceIdentification,
    component: Start,
    meta: { disableSessionExpiration: true },
  },
  {
    path: '/start',
    name: pages.start,
    component: Start,
    meta: { disableSessionExpiration: true },
  },
];

const router = new Router({
  mode: 'history',
  routes: [
    ...spouseRoutes,
    {
      path: '/',
      name: pages.createApplication,
      component: PersonalDetails,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/login',
      name: pages.login,
      component: Login,
      props: { role: ROLE.APPLICANT },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/login-co-applicant',
      name: pages.loginCoApplicant,
      component: Login,
      props: { role: ROLE.CO_APPLICANT },
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/personal-details',
      name: taskDefinitionKeys.SubmitPersonalDetails,
      component: PersonalDetails,
    },
    {
      path: '/identify',
      name: taskDefinitionKeys.IdentifyOnline,
      component: PersonalDetails,
    },
    {
      path: '/incomes-liabilities',
      name: taskDefinitionKeys.SubmitIncomesAndLiabilities,
      component: IncomesLiabilities,
    },
    {
      path: '/spouse-incomes-liabilities',
      name: taskDefinitionKeys.SpouseIncomesAndLiabilities,
      component: SpouseOrCoBorrowerIncomesLiabilities,
      props: { coApplicantRole: CO_APPLICANT_ROLES.SPOUSE },
    },
    {
      path: '/co-borrower-incomes-liabilities',
      name: taskDefinitionKeys.SubmitCoBorrowerIncomesAndLiabilities,
      component: SpouseOrCoBorrowerIncomesLiabilities,
      props: { coApplicantRole: CO_APPLICANT_ROLES.CO_BORROWER },
    },
    {
      path: '/co-borrower-address-and-financial-data',
      name: taskDefinitionKeys.SubmitCoborrowerAddressAndFinancialData,
      component: SubmitCoborrowerAddressAndFinancialData,
    },
    {
      path: '/bank-details-and-address',
      name: taskDefinitionKeys.SubmitBankDetailsAndAddress,
      component: SubmitBankDetailsAndAddress,
    },
    {
      path: '/address',
      name: taskDefinitionKeys.SubmitAddress,
      component: AddressPage,
    },
    {
      path: '/bank-details',
      name: taskDefinitionKeys.SubmitBankDetails,
      component: BankDetails,
    },
    {
      path: '/submit-bank-account-number',
      name: taskDefinitionKeys.SubmitBankAccountNumber,
      component: SubmitBankAccountNumber,
    },
    {
      path: '/contract-loading',
      name: taskDefinitionKeys.ViewAndSignContractContentLoader,
      component: ContractPageLoader,
    },
    {
      path: '/documents',
      name: taskDefinitionKeys.UploadDocuments,
      props: {
        canSelectPreviouslyUploadFile: true,
        shouldSetFilesForAllSections: true,
      },
      component: DocumentsPage,
    },
    {
      path: '/leasing-documents',
      name: taskDefinitionKeys.UploadLeasingDocuments,
      props: { canSelectPreviouslyUploadFile: true },
      component: DocumentsPage,
    },
    {
      path: '/real-estate-documents',
      name: taskDefinitionKeys.UploadRealEstateDocuments,
      props: {
        canSelectPreviouslyUploadFile: true,
        shouldSetAllFilesForMandatorySections: true,
      },
      component: DocumentsPage,
    },
    {
      path: '/refinancing-documents',
      name: taskDefinitionKeys.UploadRefinancingDocuments,
      props: {
        isRefinancingPage: true,
        shouldSetFilesForAllSections: true,
      },
      component: DocumentsPage,
    },
    {
      path: '/sign-contract',
      name: taskDefinitionKeys.ViewAndSignContract,
      component: SignContract,
    },
    {
      path: '/product-selection',
      name: taskDefinitionKeys.SelectProduct,
      component: SelectProduct,
    },
    {
      path: '/negative-decision1',
      name: 'ReadNegativeDecision',
      component: OfferNegativeDecision,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/negative-decision2',
      name: 'ShowContractSigningNegativePage',
      component: OfferNegativeDecision,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/whats-next-bank-link',
      name: taskDefinitionKeys.WhatsNextPageBankLink,
      component: WhatsNextPageBankLink,
      meta: { disableSessionExpiration: true },
    },
    {
      path: '/choose-continuation',
      name: taskDefinitionKeys.ChooseContinueOption,
      component: ChooseContinueOption,
    },
    {
      path: '/choose-continuation-after-re',
      name: taskDefinitionKeys.ChooseContinueOptionAfterReData,
      component: ChooseContinueOption,
    },
    {
      path: '/submit-real-estate-info',
      name: taskDefinitionKeys.SubmitRealEstateInfo,
      component: SubmitRealEstateInfo,
    },
    {
      path: '/provide-bas-via-open-banking',
      name: taskDefinitionKeys.ProvideBasViaOpenBanking,
      component: ProvideBasViaOpenBanking,
    },
    {
      path: '/provide-spouse-bas-via-open-banking',
      name: taskDefinitionKeys.ProvideSpouseBasViaOpenBanking,
      component: ProvideBasViaOpenBanking,
    },
    // TODO: remove when there are no more active old links
    // Support old continue links
    {
      path: '/continue/:externalId',
      name: pages.continue,
      component: OldContinue,
    },
    ...startRoutes,
    ...errorPageRoutes,
    ...reusedRoutes,
    ...offerRoutes,
    ...thankYouRoutes,
    ...partnerRoutes,
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const isStepAlreadyMigrated = getMigratedSteps().includes(to.name);
  const shouldRedirect = !!(
    isFeatureRedirectionToFallbackUrlEnabled() &&
    fallbackWebLoanApplicationUrl &&
    to.query.externalId &&
    isStepAlreadyMigrated
  );

  if (shouldRedirect) {
    const params = to.fullPath.substring(to.fullPath.indexOf('?'));

    window.location.href = `${fallbackWebLoanApplicationUrl}${params}`;

    return;
  }

  if (!from.name && to.query.externalId && !startRoutes.find(route => route.name === to.name)) {
    next({
      name: pages.start,
      query: to.query,
    });
  }

  next();
});

export default router;
